import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw.div`w-full lg:max-w-md relative lg:-mt-32`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-0`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-2xl sm:text-3xl font-bold text-center`}
  }
  input,textarea {
    ${tw`bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const CalculatorHeadLine = tw.h3`font-bold text-xl`
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`w-full`;
const ErrorLabel = tw.p`text-red-500 text-sm mt-1`;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-6 py-3 bg-gray-100 inline-block text-primary-500 w-full tracking-wide text-center rounded-full tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

export default () => {
  const [investment, setInvestment] = useState('');
  const [duration, setDuration] = useState('');
  const [profit, setProfit] = useState(null);
  const [errors, setErrors] = useState({}); // State to hold validation errors

  // Different return rates for the first 2 days and beyond
const day1Rate = 50;
const day2Rate = 50;
const returnRate = 20; // For day 3 and beyond

  // Validation checks for investment and duration
  const validateInputs = () => {
    let valid = true;
    let newErrors = {};

    if (!investment || isNaN(investment) || investment <= 0) {
      newErrors.investment = 'Bitte geben Sie einen gültigen Anlagebetrag größer als 0 ein.';
      valid = false;
    }

    if (!duration || isNaN(duration) || duration <= 0) {
      newErrors.duration = 'Bitte geben Sie eine gültige Laufzeit (Tage) größer als 0 ein.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const calculateProfit = () => {
    if (!validateInputs()) return; // Prevent calculation if validation fails

    const investmentAmount = parseFloat(investment);
    const days = parseInt(duration);
    let compoundedAmount = investmentAmount;
  
    // Apply the different rates for the first two days
    if (days >= 1) {
      compoundedAmount *= (1 + day1Rate / 100); // First day rate
    }
    if (days >= 2) {
      compoundedAmount *= (1 + day2Rate / 100); // Second day rate
    }
    if (days > 2) {
      const remainingDays = days - 2;
      compoundedAmount *= Math.pow(1 + returnRate / 100, remainingDays); // Rate for day 3 and beyond
    }
  
    let totalProfit = compoundedAmount - investmentAmount;
    totalProfit += investmentAmount;
    setProfit(totalProfit.toFixed(2));
  };

  return (
    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <CalculatorHeadLine>Entfessle Dein Krypto-Einkommen sofort—Berechne jetzt Deine Gewinne!</CalculatorHeadLine>
                  <InputContainer>
                    <Label htmlFor="name-input">Investition (€)</Label>
                    <Input
                        id="investment"
                        value={investment}
                        onChange={(e) => {setInvestment(e.target.value); setProfit(null); setErrors({});}}
                        placeholder="Betrag eingeben (z.B., 4500)" 
                        type="number"
                    />
                    {errors.investment && <ErrorLabel>{errors.investment}</ErrorLabel>}
                  </InputContainer>
                  <InputContainer>
                    <Label  htmlFor="email-input">Dauer (Tage)</Label>
                    <Input
                      type="number"
                      id="duration"
                      value={duration}
                      onChange={(e) => {setDuration(e.target.value); setProfit(null); setErrors({});}}
                      placeholder="Tage eingeben (z.B., 6)"           
                    />
                    {errors.duration && <ErrorLabel>{errors.duration}</ErrorLabel>}
                  </InputContainer>
                  {profit && <CalculatorHeadLine>Deine geschätzte Rendite: <b>{profit}€</b></CalculatorHeadLine>}

                  {profit ? (<PrimaryButton as="a" href="https://t.me/+dgHC3B6-XwsxNzA0" onClick={calculateProfit}>Fordere jetzt deine Gewinne ein!</PrimaryButton>) :
                  (<SubmitButton onClick={calculateProfit}>JETZT BERECHNEN</SubmitButton>)
                  }  
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
