import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Müheloses Krypto-Investieren für alle.",
      description: "Unsere KI-gesteuerte Plattform nimmt das Rätselraten beim Krypto-Investieren. Legen Sie Ihren Betrag fest, wählen Sie Ihre Dauer und lassen Sie unsere intelligenten Algorithmen Ihr Portfolio wachsen – keine Erfahrung erforderlich."
    },
    { 
      imageSrc: SupportIconImage, 
      title: "Steigern Sie Ihre Gewinne ohne Aufwand.",
      description: "Unser KI-System maximiert automatisch Ihre Gewinne mit bewährten Krypto-Strategien und lässt Ihr Vermögen wachsen, selbst wenn Sie offline oder beschäftigt sind." 
    },
    { 
      imageSrc: CustomizeIconImage, 
      title: "Garantierte Renditen, kein Rätselraten.",
      description: "Unsere Plattform garantiert tägliche Renditen durch bewährte, sich verzinsende Strategien und bietet Ihnen ein stetiges Gewinnwachstum, während andere mit Markunsicherheiten konfrontiert sind.",
     },
    { 
      imageSrc: ReliableIconImage, 
      title: "Vollständige Transparenz, Echtzeitgewinne.",
      description: "Von Tag eins an können Sie Ihre Ergebnisse in Echtzeit mit voller Klarheit verfolgen. Unsere Plattform stellt sicher, dass Sie immer die Kontrolle über Ihre Investitionen und Gewinne haben.", 
    },
    { 
      imageSrc: FastIconImage, 
      title: "Einfache Bedienung für alle Anleger – Anfänger oder Profi.",
      description: "Unsere Plattform ist für alle konzipiert und einfach sowie jargonfrei. Beginnen Sie, Ihr Vermögen mit nur wenigen Klicks zu vermehren – keine Erfahrung erforderlich.", 
    },
    { 
      imageSrc: SimpleIconImage, 
      title: "Treten Sie einer Gemeinschaft erfolgreicher Anleger bei.",
      description: "Indem Sie unserer Plattform beitreten, werden Sie Teil einer florierenden Anlegergemeinschaft. Vernetzen Sie sich, teilen Sie Erkenntnisse und vermehren Sie Ihr Vermögen gemeinsam mit anderen.", 
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
