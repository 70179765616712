import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Merkmale</Subheading>}
        heading={
          <>
            Entdecken Sie die Kraft hinter Ihrer <HighlightedText>Investition.</HighlightedText>
          </>
        }
        description={
          <>
          Unsere KI-gesteuerten Krypto-Strategien und fortschrittlichen Funktionen sind darauf ausgelegt, Ihre Renditen zu maximieren, Ihr Erlebnis zu vereinfachen und Ihnen die volle Kontrolle über Ihr finanzielles Wachstum zu geben.
          </>
        }
      />

      <GetStarted/>

      <Testimonial
        subheading={<Subheading>Was unsere Anleger sagen</Subheading>}
        heading={
          <>
            Echte Ergebnisse: <HighlightedText>Hören Sie von unseren Anlegern.</HighlightedText>
          </>
        }
      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
