import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import anna from "images/anna.jpeg";
import laura from "images/laura.jpeg";
import felix from "images/felix.jpeg" 

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = "Was unsere Anleger sagen",
  heading = "Customer's Review",
  testimonials = [
    {
      imageSrc: laura,
      quote:
        "Zuerst dachte ich, das sei zu schön, um wahr zu sein. Aber nach nur wenigen Wochen sprachen meine Ergebnisse für sich. Ich ging von der Sorge um meine finanzielle Zukunft zu der Planung von Reisen, die ich mir nie hätte leisten können. Diese Plattform hat mir mehr als nur Renditen gegeben; sie hat mir die Freiheit gegeben, das Leben zu leben, von dem ich immer geträumt habe.",
      customerName: "Laura Schmidt"
    },
    {
      imageSrc: anna,
      quote:
        "Ich war müde von dem Stress, der mit dem traditionellen Handel verbunden ist, aber diese Plattform hat alles verändert. Ich liebe, wie transparent alles ist – ich kann sehen, wie meine Gewinne jeden Tag wachsen. Das Beste? Ich muss nicht stundenlang den Markt beobachten. Ich kann es jedem empfehlen, der nach einem smarteren Weg sucht, in Krypto zu investieren!",
      customerName: "Anna Müller"
    },
    {
      imageSrc: felix,
      quote:
        "Ich hatte Schwierigkeiten, meine Ersparnisse für mich arbeiten zu lassen, bis ich diese Plattform fand. Nach nur wenigen Monaten begann ich, konstante Renditen zu sehen, die mein Leben völlig verändert haben. Ich konnte meinen Nebenjob kündigen und mehr Zeit mit meiner Familie verbringen. Ich bin der lebende Beweis, dass diese Plattform die finanzielle Situation von jedem verbessern kann.",
      customerName: "Felix Hoffmann"
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Image src={testimonial.imageSrc} />
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
